<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-configuration-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <label for="ValueType" class="form-label required">{{
                $t(
                  "Configurations.Type",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedConfiguration"
                :allowEmpty="false"
                :data="valueTypeData"
                @onChange="onChangeForValueType"
              />
            </div>
            <div class="col-md-4">
              <label for="Key" class="form-label required"
                >{{
                  $t(
                    "Configurations.Key",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Key"
                v-model="configurationData.key"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-4">
              <label for="Value" class="form-label required">{{
                $t(
                  "Configurations.Value",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="Value"
                v-model="configurationData.value"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required">{{
                $t(
                  "Configurations.Description",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                class="form-control"
                id="Description"
                rows="3"
                v-model="configurationData.description"
                :spellcheck="this.$isTextSpellCheck"
                required
              ></textarea>
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="configurationData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "ConfigurationEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      selectedConfiguration: [],
      configurationData: {},
      valueTypeData: [
        {
          key: "1",
          value: this.$t(
            "Configurations.String",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "Configurations.Integer",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "Configurations.Bool",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "Configurations.Encrypted",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeForValueType: function (selected) {
      this.configurationData.valueTypeId = selected.key;
    },
    getConfigurationTypeName(valueTypeId) {
      var text = this.$t(
        "Configurations.String",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (valueTypeId == 2) {
        text = this.$t(
          "Configurations.Integer",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 3) {
        text = this.$t(
          "Configurations.Bool",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 4) {
        text = this.$t(
          "Configurations.Encrypted",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getConfiguration() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ConfigurationGet?id={0}",
            this.$route.params.configurationId
          )
        )
        .then((response) => {
          this.configurationData = response.data;
          if (!String.isNullOrWhiteSpace(response.data.valueTypeId)) {
            this.selectedConfiguration.push({
              key: response.data.valueTypeId.toString(),
              value: this.getConfigurationTypeName(response.data.valueTypeId),
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-configuration-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ConfigurationEditCustom", { ...this.configurationData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/Configuration/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getConfiguration();
  },
};
</script>
